import React from 'react';
// Svgs
// import { ReactComponent as logoOhif } from './../../assets/svgs/ohif-logo.svg';
// import medimazeFull from './../../assets/svgs/logo-medimaze.svg';
import { ReactComponent as logoOhif } from './../../assets/svgs/medim.svg';
import { ReactComponent as Image } from './../../assets/svgs/image.svg';
import { ReactComponent as Pin } from './../../assets/svgs/pin.svg';
import { ReactComponent as Time } from './../../assets/svgs/time.svg';

import { ReactComponent as mmImageMensuration } from './../../assets/mm-icons/mm-image-mensuration.svg';
import { ReactComponent as closeicons } from './../../assets/mm-icons/close.svg';
const SVGS = {
  'logo-ohif': logoOhif,
  // 'logo-medimaze': medimazeFull,
  'logo-image': Image,
  'logo-pin': Pin,
  'logo-time': Time,
  'logo-close': closeicons,
  'mm-image-mensuration': mmImageMensuration,
};
/**
 * Return the matching SVG as a React Component.
 * Results in an inlined SVG Element. If there's no match,
 * return `null`
 */
export default function getSvg(key, props) {
  if (!key || !SVGS[key]) {
    return React.createElement('div', null, 'Missing SVG');
  }

  return React.createElement(SVGS[key], props);
}

export { SVGS };
